import type { MeansContent } from '@models/sections';
import './means.scss';

interface Props {
  data: MeansContent;
}

function Means({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, list } = data;

  const meansCardList = list.map((item) => {
    const cardList = item.list.map((subitem) => (
      <li key={subitem.title}>
        <div className='means__card-item'>
          <div className='means__card-top'>
            <hr className='means__card-line' />
            <h3 className='means__card-title' dangerouslySetInnerHTML={{ __html: subitem.title }}></h3>
          </div>
          <div className='means__card-text' dangerouslySetInnerHTML={{ __html: subitem.text }}></div>
        </div>
      </li>
    ));

    return (
      <li key={item.picture}>
        <div className='means__card'>
          <picture className='means__card-img-wrap'>
            <img src={item.picture} alt={item.picture} />
          </picture>
          <ul className='means__card-list'>
            { cardList }
          </ul>
        </div>
      </li>
    );
  });

  return (
    <section className='means'>
      <div className='means__inner'>
        <h2 className='h2 means__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <ul className='means__content'>
          { meansCardList }
        </ul>
      </div>
    </section>
  );
}

export default Means;
