import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import BackLink from '@components/blocks/back-link/back-link';
import PageLinks from '@components/blocks/page-link/page-links';
import MiningMain from '@components/blocks/mining-main/mining-main';
import MiningInfo from '@components/blocks/mining-info/mining-info';
import BannerLink from '@components/blocks/banner-link/banner-link';

import { realEstateData } from '@data';

import './real-estate.scss';

function RealEstatePage(): JSX.Element {

  const { main, info, bannerLink } = realEstateData;

  const backLink = {
    link: '/raise-capital',
    pageName: 'Raise Capital'
  };

  const pageLinks = {
    prev: {
      link: '/businesses',
      title: 'For Businesses Looking to Issue Debt'
    },
    next: {
      link: '/producers',
      title: 'For Metals producers or sellers'
    }
  };

  return (
    <>
      <Header />
      <main className='main'>
        <BackLink backlink={backLink} />
        <MiningMain data={main} />
        <MiningInfo data={info} />
        <PageLinks pagelinks={pageLinks} />
        <BannerLink data={bannerLink} />
      </main>
      <Footer />
    </>
  );
}

export default RealEstatePage;
