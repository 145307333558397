import BaseSvg from '@components/base/svg/svg';
import classNames from 'classnames';
import './issuance-step.scss';

interface Props {
  step: {
    id: string;
    number: string;
    icon: string;
    title: string;
    active: boolean;
  };
  onClick: () => void;
}

function IssuanceStep({ step, onClick }: Props): JSX.Element {

  const { number, icon, title, active } = step;

  const classes = classNames(
    'issuance-step',
    {
      'issuance-step--active': active
    }
  );

  return (
    <div className={classes} onClick={onClick}>
      <span className='issuance-step__number'>{number}</span>
      <BaseSvg name={icon} />
      <span className='issuance-step__title'>{title}</span>
    </div>
  );
}

export default IssuanceStep;
