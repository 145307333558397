import { useState, useEffect } from 'react';

import IssuanceStep from './step/issuance-step';
import IssuanceBlock from './block/issuance-block';

import type { IssuanceContent } from '@models/sections';
import type { IssuanceStepType, IssuanceBlockType } from '@models/issuance-content';
import './issuance.scss';

interface Props {
  data: IssuanceContent;
}

function Issuance({ data }: Props): JSX.Element {

  const [stepButtons, setStepButtons] = useState<IssuanceStepType[] | null>(null);
  const [blocks, setBlocks] = useState<IssuanceBlockType[] | null>(null);
  const [currentBlock, setCurrentBlock] = useState<IssuanceBlockType | null>(null);

  useEffect(() => {
    if (data) {
      const { stepsData, blocksData } = data;
      setStepButtons(stepsData);
      setBlocks(blocksData);
      setCurrentBlock(blocksData[0]);
    }
  }, [data]);

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title } = data;

  const stepList = stepButtons?.map((step, index) => {
    const lastElement = (stepButtons.length - 1) === index;

    return (
      <li key={step.id}>
        <IssuanceStep step={step} onClick={() => handleStepClick(step.id)} />
        {
          !lastElement &&
          <hr className='issuance__steps-line' />
        }
      </li>
    );
  });

  const handleStepClick = (id: string) => {
    const updatedSteps = stepButtons?.map((step) => ({
      ...step,
      active: step.id === id
    }));
    updatedSteps && setStepButtons(updatedSteps);

    const targetBlock = blocks?.find((block) => block.id === id);
    targetBlock && setCurrentBlock(targetBlock);
  };

  return (
    <section className='issuance'>
      <div className='issuance__inner'>
        <div className='issuance__top'>
          <h2 className='h2 issuance__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className='issuance__bg'></div>
        </div>
        <div className='issuance__content'>
          <ul className='issuance__steps'>
            { stepList }
          </ul>
          {currentBlock && <IssuanceBlock block={currentBlock} />}
        </div>
      </div>
    </section>
  );
}

export default Issuance;
