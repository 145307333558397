import './next.scss';
import { NextItem, MainButton } from '@types';
import BaseButton from "@components/base/button/button";
import BaseSvg from "@components/base/svg/svg";

interface NextProps {
  data: NextItem
}


function Next(props: NextProps): JSX.Element {
  const {
    data
  } = props;

  let button;
  if (data.button) {
    button = <BaseButton to={data.button?.to} classes='menu__action-item' primary outlink>
      <span>{data.button?.title}</span>
    </BaseButton>;
  }

  let info;
  if (data.description) {
    info = '';
  }

  return (
    <section className='next'>
      <div className={'next__bg'}/>
      <div className='next__inner'>
        <h2 className='h2 next__title'>{ data.title }</h2>
        <div className={'next__text'} dangerouslySetInnerHTML={{ __html: data.text }}/>
        <div className={'next__bottom'}>
          {button}
          {info}

          <div className={'next-info'}>
            <span className={'next-info__btn'}>
              <BaseSvg name={'info'} />
            </span>
            <div className={'next-info__content'} dangerouslySetInnerHTML={{__html: data.description}}/>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Next;
