import Insight from './insight/insight';

import type { InsightsContent } from '@models/sections';
import './insights.scss';

interface Props {
  data: InsightsContent;
}

function Insights({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, list } = data;

  const insightList = list.map((item) => (
    <li key={item.title}>
      <Insight data={item} />
    </li>
  ));

  return (
    <section className='insights'>
      <div className='insights__inner'>
        <div className='insights__bg'></div>
        <h2 className='h2 insights__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <ul className='insights__list'>
          { insightList }
        </ul>
      </div>
    </section>
  );
}

export default Insights;
