import Modal from '@components/base/modal/modal';
import './staff.scss';
import { useState } from 'react';
import { StaffItem } from '@types';
import { createMarkup } from '@utils/utils';

type StaffProps = { [x: string]: any };

type ModalState = {
  isOpened: boolean;
  content: {
    [x: string]: string;
  };
};

function Staff({ staff }: StaffProps): JSX.Element {
  const { title, items: links } = staff;
  const [modalState, setModalState] = useState<ModalState>({
    isOpened: false,
    content: {}
  });

  const onStaffClick = (id: string) => {
    setModalState({
      isOpened: true,
      content: links.find((item: StaffItem) => item.id === id)
    });
  };

  const items = links.map(({ id, name, image, position, detailText }: { [x: string]: string }) => (
    <li key={name} id={id} className='staff__item' onClick={() => onStaffClick(id)}>
      <img className='staff__image' src={image} alt={name} />
      <h4 className='h4 staff__name'>{name}</h4>
      <span className='staff__position'>{position}</span>
      <p className='staff__description'>{detailText}</p>
    </li>
  ));

  const modalContent: JSX.Element =
    <article className='m-staff'>
      <img className='m-staff__image' src={modalState.content.image}/>
      <div className='m-staff__info'>
        <span className='m-staff__position'>{modalState.content.position}</span>
        <h4 className='h4 m-staff__name'>{modalState.content.name}</h4>
        <p className='m-staff__description' dangerouslySetInnerHTML={createMarkup(modalState.content.detailText)} />
      </div>
    </article>
  ;

  return (
    <section className='staff'>
      <div className='staff__inner'>
        <h2 className="h2 staff__title">{title}</h2>
        <ul className="staff__list">
          {items}
        </ul>
      </div>
      <Modal isOpened={modalState.isOpened} onClose={() => setModalState({ isOpened: false, content: {}})}>
        {modalContent}
      </Modal>
    </section>
  )
}

export default Staff;
