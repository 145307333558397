import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import './textarea.scss';

interface SchemaItem {
  as?: string;
  type?: string;
  ref?: string;
  placeholder?: string;
  label?: string;
  name: string;
  mask?: string;
  disabled?: boolean;
  readonly?: boolean;
}

interface TextareaProps {
  value?: string | boolean | number | null;
  schema: SchemaItem;
  classes?: string;
  bordered?: boolean;
  disabled?: boolean;
  onInput?: (evt:React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChange?: (evt:React.ChangeEvent<HTMLTextAreaElement>) => void;
}

function BaseTextarea(props: TextareaProps): JSX.Element {

  const { register, formState: { errors } } = useFormContext();

  const {
    classes,
    value,
    schema,
    disabled = false,
    bordered = false,
    onInput,
    onChange
  } = props;

  const textareaClasses = classNames(
    classes,
    'textarea',
    {
      'textarea--disabled': disabled,
      'textarea--bordered': bordered,
      'textarea--error': !!errors[schema.name],
    }
  );

  return (
    <div className={textareaClasses}>
      <div className='textarea__inner'>
        <div className="textarea__top">
          {schema.label && <label className='textarea__label'>{schema.label}</label>}
          <ErrorMessage
            errors={errors}
            name={schema.name}
            render={({ message }) => <p className='input__error'>{message}</p>}
          />
        </div>
        <textarea
          className='textarea__control'
          id={schema.name}
          placeholder={schema.placeholder}
          readOnly={schema.readonly ? schema.readonly : false}
          disabled={schema.disabled ? schema.disabled : disabled}
          onInput={onInput}
          {...register(schema.name)}
          aria-invalid={!!errors[schema.name]}
        />
      </div>
    </div>
  );
}

export default BaseTextarea;
