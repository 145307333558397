import type { AccessContent} from '@models/sections';
import { createMarkup } from '@utils/utils';
import './access.scss';

interface Props {
  data: AccessContent;
}

function Access({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { imgLarge, title, text } = data;

  return (
    <section className='access'>
      <div className='access__inner'>
        <h2 className='access__title' dangerouslySetInnerHTML={createMarkup(title)}></h2>
        {
          text && (
            <p className='access__description' dangerouslySetInnerHTML={createMarkup(text)} />
          )
        }
      </div>
      {
        imgLarge &&
        <picture>
          {
            typeof imgLarge === 'object' &&
            <img
              className='access__image'
              src={imgLarge.url}
              alt={imgLarge.alt}
            />
          }
        </picture>
      }
    </section>
  );
}

export default Access;
