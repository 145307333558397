import type { InsightType } from '@models/insight';
import './insight.scss';

interface Props {
  data: InsightType;
}

function Insight({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, text } = data;

  return (
    <div className='insight'>
      <h3 className='insight__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
      <div className='insight__text' dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  );
}

export default Insight;
