import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Banner from '@components/blocks/banner/banner';
import Means from '@components/blocks/means/means';
import Issuance from '@components/blocks/issuance/issuance';
import { howItWorks } from '@data';
import './how-it-works.scss';

function HowItWorksPage(): JSX.Element {

  const { banner, means, issuance} = howItWorks;

  return (
    <>
      <Header />
      <main className='main'>
        <Banner data={banner} />
        <Means data={means} />
        <Issuance data={issuance} />
      </main>
      <Footer />
    </>
  );
}

export default HowItWorksPage;
