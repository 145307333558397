import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react';
import apiFetch from '@composables/useFetch';
import type { PromoContent, AccessContent, AlternativeContent, AdvantagesContent, NewsContent, PartnersContent } from '@models/sections';

interface Data {
  promo: PromoContent;
  access: AccessContent;
  alternative: AlternativeContent;
  advantages: AdvantagesContent;
  news: NewsContent;
  partners: PartnersContent;
}

interface Context {
  item: Data;
  getMainPageData: () => Promise<void>;
}

const MainContext = createContext<Context>({
  item: {
    promo: [],
    access: null,
    alternative: null,
    advantages: null,
    news: null,
    partners: null,
  },
  getMainPageData: async () => {
    await new Promise((resolve) => resolve);
    console.log('Данные главной страницы не загружены!');
  },
});

export const useMainStore = () => useContext(MainContext);

export function MainProvider({ children }: PropsWithChildren<JSX.Element>) {
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem] = useState<Data>({
    promo: [],
    access: null,
    alternative: null,
    advantages: null,
    news: null,
    partners: null,
  });

  async function getMainPageData() {
    setIsLoading(true);
    await apiFetch('/wp-json/wp/v2/pages?slug=home')
      .then((data) => {
        const newData: Data = {
          promo: (data as any)[0].acf.slider,
          access: (data as any)[0].acf.about,
          alternative: (data as any)[0].acf.alternative,
          advantages: (data as any)[0].acf.partner,
          news: (data as any)[0].acf.news,
          partners: (data as any)[0].acf.partners,
        };

        setItem(newData);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке данных:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getMainPageData();
  }, []);

  useEffect(() => {
    console.log(item);
  }, [item]);

  return (
    <MainContext.Provider value={{ item, getMainPageData }}>
      {isLoading ? (<div>Загрузка...</div>) : children}
    </MainContext.Provider>
  );
}

