import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';
import './page-links.scss';

interface Props {
  pagelinks: {
    prev: {
      link: string;
      title: string;
    };
    next: {
      link: string;
      title: string;
    };
  };
}

function PageLinks({ pagelinks }: Props): JSX.Element {

  const { prev, next } = pagelinks;

  return (
    <div className='page-links'>
      <div className="page-links__inner">
        <BaseButton to={prev.link} classes='page-links__button page-links__button--prev' bordered transparent inlink>
          <BaseSvg name='arrow-left'/>
          <span dangerouslySetInnerHTML={{__html: prev.title}}></span>
        </BaseButton>
        <BaseButton to={next.link} classes='page-links__button page-links__button--next' bordered transparent inlink>
          <BaseSvg name='arrow-right'/>
          <span dangerouslySetInnerHTML={{__html: next.title}}></span>
        </BaseButton>
      </div>
    </div>
  );
}

export default PageLinks;
