import { footerSocials } from '@data';
import BaseButton from '@components/base/button/button';
import BaseSvg from '@components/base/svg/svg';
import './socials.scss';

function BlockSocials(): JSX.Element {

  const listSocials = footerSocials.map((social) => (
    <li key={social.id}>
      <BaseButton to={social.href} classes='socials-item' outlink>
        <BaseSvg name={social.name} />
      </BaseButton>
    </li>
  ));

  return (
    <div className='socials'>
      <ul className='socials-list'>
        { listSocials }
      </ul>
    </div>
  );
}

export default BlockSocials;
