import './menu.scss';
import { Link } from 'react-router-dom';
import BaseButton from '@components/base/button/button';
import { useRef, useState } from 'react';
import { headerNav, headerActions } from '@data';

type MenuProps = {
  onMenuClick: () => void;
};

function Menu({ onMenuClick }: MenuProps): JSX.Element {
  const [menuState, setMenuState] = useState<boolean>(false);
  const sublists = useRef<HTMLElement[]>([]);
  const items = useRef<HTMLElement[]>([]);

  const onCloseClick = (): void => {
    setMenuState(false);
    onMenuClick();
  };

  const onListItemClick = (i: number, e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    items.current[i].classList.toggle('_active');

    if (sublists.current[i]) {
      sublists.current[i].style.height = `${items.current[i].classList.contains('_active') ? sublists.current[i].scrollHeight : 0}px`;
    }
  };

  const listItems = headerNav.map(({ title, to, list }, i) => (
    <li
      key={title}
      ref={(el) => el && (items.current[i] = el)}
      onClick={(e) => onListItemClick(i, e)}
    >
      <Link to={to} className='menu__list-item'>{title}</Link>
      {
        list && (
          <ul
            className='menu__sublist'
            ref={(el) => el && (sublists.current[i] = el)}
          >
            {
              list.map((item) => (
                <li key={item.title}>
                  <Link to={item.to} className='menu__sublist-item'>{item.title}</Link>
                </li>
              ))
            }
          </ul>
        )
      }
    </li>
  ));

  const actionsItems = headerActions.map(({title, to, href, primary, secondary}) => {
    if (to) {
      return (
        <li key={title} >
          <BaseButton to={to} classes='menu__action-item' inlink primary={primary} secondary={secondary}>
            <span>{title}</span>
          </BaseButton>
        </li>
      );
    } else if (href) {
      return (
        <li key={title} >
          <BaseButton to={href} classes='menu__action-item' outlink primary={primary} secondary={secondary}>
            <span>{title}</span>
          </BaseButton>
        </li>
      );
    } else {
      // Возвращаем пустой элемент или другое значение по умолчанию
      return <li key={title} className='contacts__item'>The header action has an unknown link type</li>;
    }
  });

  return (
    <div className={`menu ${menuState ? '_active' : ''}`}>
      <div className='menu__inner'>
        <div className='menu__top'>
          <span className='menu__title'>MENU</span>
          <button
            className='menu__close'
            type='button'
            onClick={onCloseClick}
          >
            <span/>
          </button>
        </div>
        <nav className='menu__nav'>
          <ul className='menu__list'>
            {listItems}
          </ul>
          <ul className='menu__actions'>
            {actionsItems}
          </ul>
        </nav>
      </div>
      <div className='menu__overlay' onClick={onCloseClick}></div>
    </div>
  );
}

export default Menu;
