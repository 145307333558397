import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import NewsLinks from '@components/blocks/news-links/news-links';
import Insights from '@components/blocks/insights/insights';
import Questions from '@components/blocks/questions/questions';

import { resources } from '@data';
import './resources.scss';

function ResourcesPage(): JSX.Element {

  const { newsLinks, insights, questions } = resources;

  return (
    <>
      <Header />
      <main className='main resources-page'>
        <div className='resources-page__top'>
          <h1 className='h1'>Resources</h1>
          <div className='resources-page__top-bg'></div>
        </div>
        <NewsLinks data={newsLinks} />
        <Insights data={insights} />
        <Questions data={questions} />
      </main>
      <Footer />
    </>
  );
}

export default ResourcesPage;
