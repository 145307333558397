import classNames from 'classnames';
import { CommoditiesContent } from '@types';
import Commodity from '../commodity/commodity';
import './commodities.scss';

interface Props {
  data: CommoditiesContent;
}

function Commodities(props: Props): JSX.Element {

  const { title, list } = props.data;

  const commoditiesContent = list.map((item, index) => (
    <Commodity data={item} key={item.id} reversed={ index % 2 !== 0 } />
  ));

  return (
    <section className='commodities'>
      <div className='commodities__inner'>
        <h1 className='commodities__title'>{ title }</h1>
        <ul className='commodities__list'>
          { commoditiesContent }
        </ul>
      </div>
    </section>
  );
}

export default Commodities;
