import './story.scss';

export type StoryProps = { [x: string]: any };

function Story({ story }: StoryProps): JSX.Element {
  const { head, bottom } = story;

  const items = bottom.map(({ title, description } : { title: string; description?: string  }) => (
    <li key={title} className='story__bottom-item'>
        <h3 className="h3 story__bottom-title">{title}</h3>
        <p className="story__bottom-description">{description}</p>
    </li>
  ));

  return (
    <section className='story'>
      <picture>
        {/* <source srcSet={'/img/story/story-lg.png'}
          type='image/webp'
          media='(min-width: 1280px)'
        />
        <source srcSet={'/img/story/story-md.png'}
          type='image/webp'
          media='(min-width: 744px)'
        /> */}
        <img
          className='story__image'
          src={'/img/story/story.png'}
          alt={head.title}
        />
      </picture>
      <div className='story__inner'>
        <h1 className="h1 story__title">{head.title}</h1>
        <p className="story__description">{head.description}</p>
        <ul className="story__bottom">
          {items}
        </ul>
      </div>
    </section>
  )
}

export default Story;
