import BaseSvg from '@components/base/svg/svg';
import Privilege from '../privelege/privilege';
import type { PrivilegesData } from '@types';
import './privileges.scss';

interface Props {
  data: PrivilegesData;
}

function Privileges({ data }: Props): JSX.Element {

  const { title } = data;

  const description = '<p>We go beyond creating  product descriptions for investors; by leveraging  our vast  network of trusted partners to promote your token offering. In addition, ATME will help issuers with advertising campaigns to boost visibility and attract potential investors.</p>';

  return (
    <section className='privileges'>
      <div className='privileges__bg'/>
      <div className='privileges__inner'>
        <h2 className='privileges__title' dangerouslySetInnerHTML={{__html: title}}></h2>
        <ul className="privileges__list">
          <li>
            <Privilege title='Complete suite of services'>
              <div className='privilege__content-text'>
                <p>At ATME, we offer more than just cutting-edge technology. We provide a complete suite of services that cover every aspect of the capital-raising process, all without adding unnecessary complexity to your organization.</p>
              </div>
              <h4 className='privilege__content-title'>Here’s what we deliver:</h4>
              <ul className='privilege__content-list'>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>Token Creation and Smart Contract Development</p>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>Token Type Selection</p>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>Token Creation and Smart Contract Development</p>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>Token Minting and Issuance</p>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>KYC (Know Your Customer) Procedures and AML (Anti-Money Laundering) framework</p>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>Subscription and Token Distribution</p>
                    <div className={'privilege-info'}>
                      <span className={'privilege-info__btn'}>
                        <BaseSvg name={'info'} />
                      </span>
                      <div className={'privilege-info__content'} dangerouslySetInnerHTML={{__html: description}} />
                    </div>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item'>
                    <BaseSvg name='checkmark' />
                    <p>Secondary Market Trading</p>
                  </div>
                </li>
              </ul>
            </Privilege>
          </li>
          <li>
            <Privilege title='Security and Compliance at Our Core'>
              <ul className='privilege__content-list privilege__content-list--secondary'>
                <li>
                  <div className='privilege__content-item privilege__content-item--secondary'>
                    <BaseSvg name='security-shield' />
                    <div className='privilege__content-text'>
                      <p>Licensed by the Central Bank of Bahrain (CBB), ATME ensures that all tokenization and trading activities adhere to strict regulatory standards.</p>
                      <p>Our platform integrates KYC (Know Your Customer)process and robust AML (Anti-Money Laundering) framework, making it ideal for sectors with stringent ESG and regulatory requirements.</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='privilege__content-item privilege__content-item--secondary'>
                    <BaseSvg name='compliance' />
                    <div className='privilege__content-text'>
                      <p>By leveraging the enterprise-grade Hyperledger Fabric (HLF) blockchain framework, we offer issuers a secure, permissioned private blockchain that’s customized to meet their needs. </p>
                      <p>HLF’s architecture ensures transparency, auditability, and compliance across all transactions, while its scalability and flexibility enable issuers to manage complex tokenization processes without sacrificing security.</p>
                    </div>
                  </div>
                </li>
              </ul>
            </Privilege>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Privileges;
