import { useEffect } from 'react';
import { useForm, FormProvider, Form, FormSubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import apiFetch from '@composables/useFetch';
import * as yup from 'yup';
import BaseInput from '@components/base/form/input/input';
import BaseTextarea from '@components/base/form/textarea/textarea';
import BaseButton from '@components/base/button/button';
import './feedback.scss';

type Inputs = {
  firstName: string;
  lastName: string;
  companyName: string;
  jobTitle: string;
  email: string;
  message: string;
}

interface FeedbackResponse {
  'contact_form_id': number;
  'status': string;
  'message': string;
  'posted_data_hash': string;
  'into': string;
  'invalid_fields': [];
}

const emailRegex = /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i;

function Feedback(): JSX.Element {

  const validationSchema = yup.object({
    firstName: yup.string().required('This field is required'),
    lastName: yup.string().required('This field is required'),
    companyName: yup.string().required('This field is required'),
    jobTitle: yup.string().required('This field is required'),
    email: yup.string().matches(emailRegex, { message: 'Wrong Email format', excludeEmptyString: false}).required('This field is required'),
    message: yup.string().required('This field is required')
  }).required();

  const methods = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { reset, formState, formState: { isSubmitSuccessful }, handleSubmit } = methods;

  const schemaFields = {
    firstName: {
      as: 'first name',
      name: 'firstName',
      label: 'first name*',
      validateRules: {
        required: true,
      },
    },
    lastName: {
      as: 'last name',
      name: 'lastName',
      label: 'last name*',
      validateRules: {
        required: true,
      },
    },
    companyName: {
      as: 'company name',
      name: 'companyName',
      label: 'company name*',
      validateRules: {
        required: true,
      },
    },
    jobTitle: {
      as: 'job title',
      name: 'jobTitle',
      label: 'job title*',
      validateRules: {
        required: true,
      },
    },
    email: {
      as: 'email',
      name: 'email',
      label: 'email*',
      type: 'email',
      validateRules: {
        required: true,
        pattern: {
          value: /^[a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1}([a-zA-Z0-9][\-_\.\+\!\#\$\%\&\'\*\/\=\?\^\`\{\|]{0,1})*[a-zA-Z0-9]@[a-zA-Z0-9][-\.]{0,1}([a-zA-Z][-\.]{0,1})*[a-zA-Z0-9]\.[a-zA-Z0-9]{1,}([\.\-]{0,1}[a-zA-Z]){0,}[a-zA-Z0-9]{0,}$/i,
          message: 'Wrong Email format'
        },
      },
    },
    message: {
      as: 'message',
      name: 'message',
      label: 'message*',
      validateRules: {
        required: false,
      },
    },
  };

  const onSubmit: FormSubmitHandler<Inputs> = async({ formData }) => {
    formData.append('_wpcf7_unit_tag', 'wpcf7-f213-o1');
    // Типизация, надо решить с ней вопрос!
    await apiFetch<FeedbackResponse>('/wp-json/contact-form-7/v1/contact-forms/213/feedback', {
      method: 'POST',
      body: formData,
    })
      .then((data: FeedbackResponse): void => console.log(data))
      .catch((error: Error) => {
        console.error('Error loading data:', error);
      });
  };

  const onSuccess = () => {
    console.log('success');
  };

  const onError = () => {
    console.log('error');
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [formState, isSubmitSuccessful, reset]);

  return (
    <section className='feedback'>
      <div className='feedback__inner'>
        <div className="feedback__top">
          <h1 className='h1 feedback__title'>Contact us</h1>
          <p className='feedback__text'>Please complete the form below, and a member of our team will get in touch with you shortly. Alternatively, you can email your questions directly to <a href='mailto:info@atme.com'>info@atme.com</a></p>
        </div>
        <FormProvider {...methods}>
          <Form
            className='feedback__form'
            onSubmit={onSubmit}
            onSuccess={onSuccess}
            onError={onError}
            validateStatus={(status) => status >= 200}
            noValidate
          >
            <BaseInput schema={schemaFields.firstName} />
            <BaseInput schema={schemaFields.lastName} />
            <BaseInput schema={schemaFields.companyName} />
            <BaseInput schema={schemaFields.jobTitle} />
            <BaseInput schema={schemaFields.email} />
            <BaseTextarea schema={schemaFields.message} />
            <p className='feedback__form-text'>You accept the <a target="_blank" href={`${process.env.PUBLIC_URL}/files/privacy_notice.pdf`} rel="noopener noreferrer">Privacy Policy</a> by submitting your request.</p>
            <BaseButton classes={'feedback__form-submit'} buttonType='submit' primary>Submit</BaseButton>
          </Form>
        </FormProvider>
      </div>
    </section>
  );
}

export default Feedback;
