import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';
import './back-link.scss';

interface Props {
  backlink: {
    link: string;
    pageName: string;
  };
}

function BackLink({ backlink }: Props): JSX.Element {

  const { link, pageName } = backlink;

  return (
    <div className='back-link'>
      <BaseButton to={link} classes='back-link__button' bordered transparent inlink>
        <BaseSvg name='arrow-left'/>
        <span>Back to { pageName }</span>
      </BaseButton>
    </div>
  );
}

export default BackLink;
