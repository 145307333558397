import type { MainMiningCompanies } from '@types';
import './mining-main.scss';

interface Props {
  data: MainMiningCompanies;
}

function MiningMain({ data }: Props): JSX.Element {

  const { title, text, picture } = data;

  return (
    <section className='mining-main'>
      <div className='mining-main__inner'>
        <div className="mining-main__content">
          <h1 className='mining-main__title' dangerouslySetInnerHTML={{ __html: title}}></h1>
          <div className="mining-main__text" dangerouslySetInnerHTML={{ __html: text}}></div>
        </div>
        <picture className='mining-main__image'>
          <source srcSet={picture?.lg}
            type='image/webp'
            media='(min-width: 1025px) and (max-width: 1440px)'
          />
          <source srcSet={picture?.tablet}
            type='image/webp'
            media='(min-width: 320px) and (max-width: 1024px)'
          />
          <img src={picture.original} alt={title} />
        </picture>
      </div>
    </section>
  );
}

export default MiningMain;
