import BaseSvg from '@components/base/svg/svg';

import type { NewsLinkItem } from '@models/news-link';
import './news-link.scss';

interface Props {
  data: NewsLinkItem;
}

function NewsLink({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { to, title, text, date, company } = data;

  return (
    <div className='news-link'>
      <a
        className='news-link__top'
        href={to}
        target='_blank'
        rel='noopener noreferrer'
      >
        <h3 className='news-link__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
        <BaseSvg name='arrow-right-top' />
      </a>
      <div className='news-link__text' dangerouslySetInnerHTML={{ __html: text }}>
      </div>
      <div className='news-link__bottom'>
        <span className='news-link__date'>{date}</span>
        <span className='news-link__company'>{company}</span>
      </div>
    </div>
  );
}

export default NewsLink;
