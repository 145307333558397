import BaseSvg from '../svg/svg';
import { useState, useCallback } from 'react';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  useMap,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import './map.scss';

const apiKey = process.env.REACT_APP_MAPS_API_KEY;

const center = {
  lat: 26.082775203205852,
  lng: 49.790382203045475
};

type Poi ={
  key: string;
  location: google.maps.LatLngLiteral;
}

const location: Poi = {
  key: 'atme',
  location: {lat: 26.23749600607844, lng: 50.57326163414165}
};

function BaseMap() {
  if (!apiKey) {
    throw new Error('API Key is not defined');
  }

  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    []
  );

  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  return (
    <APIProvider apiKey={apiKey} onLoad={() => console.log('Maps API has loaded.')}>
      <Map
        id={'atme'}
        mapId='Atme_id'
        className='map'
        defaultCenter={center}
        defaultZoom={9}
        gestureHandling={'greedy'}
        disableDefaultUI
        onClick={handleClose}
      >
        <AdvancedMarker
          ref={markerRef}
          className='map__marker'
          style={{transform: 'scale(1.5)'}}
          key={location.key}
          position={location.location}
          clickable
          onClick={handleMarkerClick}
        >
          <Pin background={'#8633E9'} glyphColor={'#ffffff'} borderColor={'#8633E9'} />
          {infoWindowShown && (
            <InfoWindow
              className='map__marker-info'
              maxWidth={480}
              anchor={marker}
              pixelOffset={[-300, 120]}
            >
              <h3 className='map__marker-info-title'>Address</h3>
              <div className='map__marker-info-content'>
                <div className='map__marker-info-icon'>
                  <BaseSvg name='instagram' />
                </div>
                <p>Office 1206, West Tower, Bahrain Financial Harbor, Kingdom of Bahrain</p>
              </div>
            </InfoWindow>
          )}
        </AdvancedMarker>
      </Map>
    </APIProvider>
  );
}
export default BaseMap;
