import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import BaseSvg from '@components/base/svg/svg';
import './input.scss';

interface SchemaItem {
  as?: string;
  type?: string;
  ref?: string;
  placeholder?: string;
  label?: string;
  name: string;
  mask?: string;
  icon?: string;
  disabled?: boolean;
  readonly?: boolean;
  validateRules: {
    required: boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
  };
}

interface InputProps {
  value?: string | boolean | number | null;
  schema: SchemaItem;
  classes?: string;
  bordered?: boolean;
  disabled?: boolean;
  onInput?: (evt:React.ChangeEvent<HTMLInputElement>) => void;
}

function BaseInput(props: InputProps): JSX.Element {

  const { register, formState: { errors } } = useFormContext();

  const {
    classes,
    value,
    schema,
    disabled = false,
    bordered = false,
    onInput,
  } = props;

  const inputClasses = classNames(
    classes,
    'input',
    {
      'input--disabled': disabled,
      'input--bordered': bordered,
      'input--error': !!errors[schema.name]
    }
  );

  return (
    <div className={inputClasses}>
      <div className='input__inner'>
        <div className="input__top">
          {schema.label && <label className='input__label'>{schema.label}</label>}
          <ErrorMessage
            errors={errors}
            name={schema.name}
            render={({ message }) => <p className='input__error'>{message}</p>}
          />
        </div>
        <input
          className='input__control'
          id={schema.name}
          placeholder={schema.placeholder}
          type={schema.type ? schema.type : 'text'}
          readOnly={schema.readonly ? schema.readonly : false}
          disabled={schema.disabled ? schema.disabled : disabled}
          onInput={onInput}
          {...register(schema.name)}
          aria-invalid={!!errors[schema.name]}
        />
        {schema.icon && <BaseSvg name={schema.icon} />}
      </div>
    </div>
  );
}

export default BaseInput;
