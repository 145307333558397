import './services.scss';
import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Services from '@components/blocks/services/services';
import type { BreadcrumbsItem } from '@types';
import { servicesData } from '@data';

function ServicesPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Services' }];

  const { services } = servicesData;

  return (
    <>
      <Header />
      <main className='main'>
        <Breadcrumbs breadcrumbs={breadcrumbsList} />
        <h1 className='h1 visually-hidden'>Services</h1>
        <Services data={services} />
      </main>
      <Footer />
    </>
  );
}

export default ServicesPage;
