import './button.scss';
import { Link } from 'react-router-dom';
import type { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface ButtonProps {
  buttonType?: 'button' | 'submit' | 'reset';
  to?: string;
  inlink?: boolean;
  outlink?: boolean;
  download?: boolean;
  disabled?: boolean;
  primary?: boolean;
  secondary?: boolean;
  classes?: string;
  bordered?: boolean;
  circle?: boolean;
  white?: boolean;
  transparent?: boolean;
  reversed?: boolean;
  withoutPadding?: boolean;
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}

function BaseButton(props: PropsWithChildren<ButtonProps>): JSX.Element {

  const {
    children,
    to,
    classes,
    inlink = false,
    outlink = false,
    download = false,
    primary = false,
    secondary = false,
    buttonType = 'button',
    disabled = false,
    bordered = false,
    circle = false,
    white = false,
    transparent = false,
    reversed = false,
    withoutPadding,
    onClick
  } = props;

  const buttonClasses = classNames(
    classes,
    'button',
    {
      'button--primary': primary,
      'button--secondary': secondary,
      'button--bordered': bordered,
      'button--circle': circle,
      'button--white': white,
      'button--transparent': transparent,
      'button--reversed': reversed,
      'button--without-padding': withoutPadding,
    }
  );

  let contentLink = to && <a href={'/'}>Unknown link type</a>;

  if (to && inlink) {
    contentLink = (
      <Link to={to} className={buttonClasses}>
        <div className='button__content'>
          {children}
        </div>
      </Link>
    );
  }

  if (to && outlink) {
    contentLink = (
      <a href={to} className={buttonClasses} download={download} target='_blank' rel="noreferrer">
        <div className='button__content'>
          {children}
        </div>
      </a>
    );
  }

  const content: JSX.Element = contentLink ?
    contentLink : (
      <button
        type={buttonType}
        className={buttonClasses}
        disabled={disabled}
        onClick={onClick}
      >
        <div className='button__content'>
          {children}
        </div>
      </button>
    );

  return content;
}

export default BaseButton;
