import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';
import './vacancy.scss';

interface Props {
  data: {
    id: number;
    name: string;
    description: string;
    responsibilities: {
      id: number;
      text: string;
    }[];
    actions: {
      send: {
        href: string;
      };
      more: {
        href: string;
        download: boolean;
      };
    };
  };
}

function Vacancy({ data }: Props): JSX.Element {

  const { name, description, responsibilities, actions } = data;
  const { send, more } = actions;

  const responsibilitiesList = responsibilities.map(({ id, text }) => (
    <li className='vacancy-card__item' key={id}>
      <BaseSvg name='checkmark' />
      <p dangerouslySetInnerHTML={{__html: text}}></p>
    </li>
  ));

  return (
    <div className='vacancy-card'>
      <h3 className='vacancy-card__title' dangerouslySetInnerHTML={{__html: name}}></h3>
      <div className='vacancy-card__content'>
        <p className='vacancy-card__description' dangerouslySetInnerHTML={{__html: description}}></p>
        <div className='vacancy-card__list-wrap'>
          <h4 className='vacancy-card__list-title'>Key Responsibilities:</h4>
          <ul className='vacancy-card__list'>
            {responsibilitiesList}
          </ul>
        </div>
      </div>
      <div className='vacancy-card__actions'>
        <BaseButton to={send.href} primary outlink>Send your cv</BaseButton>
        <BaseButton to={more.href} download={more.download} bordered transparent outlink>Learn more</BaseButton>
      </div>
    </div>
  );
}

export default Vacancy;
