import QuestionsBlock from './block/questions-block';

import { useState, useEffect } from 'react';
import classNames from 'classnames';

import type { QuestionsNavType, QuestionsBlockType } from '@models/question';
import type { QuestionsContent } from '@models/sections';
import './questions.scss';

interface Props {
  data: QuestionsContent;
}

function Questions({ data }: Props): JSX.Element {

  const [questionsNavList, setQuestionsNavList] = useState<QuestionsNavType[] | null>(null);
  const [blocks, setBlocks] = useState<QuestionsBlockType[] | null>(null);
  const [currentBlock, setCurrentBlock] = useState<QuestionsBlockType | null>(null);

  useEffect(() => {
    if (data) {
      const { navList, contentList } = data;
      setQuestionsNavList(navList);
      setBlocks(contentList);
      setCurrentBlock(contentList[0]);
    }
  }, [data]);

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title } = data;

  const insightsNavList = questionsNavList?.map((navItem) => {
    const navItemClasses = classNames(
      'questions__nav-item',
      { 'questions__nav-item--active': navItem.active }
    );
    return (
      <li key={navItem.id}>
        <p className={navItemClasses} onClick={() => handleNavClick(navItem.id)}>{navItem.title}</p>
      </li>
    );
  });

  const handleNavClick = (id: string) => {
    const updatedNav = questionsNavList?.map((navItem) => ({
      ...navItem,
      active: navItem.id === id
    }));
    updatedNav && setQuestionsNavList(updatedNav);

    const targetBlock = blocks?.find((block) => block.id === id);
    targetBlock && setCurrentBlock(targetBlock);
  };

  return (
    <section className='questions'>
      <div className='questions__inner'>
        <h2 className='h2 questions__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <div className='questions__main'>
          <ul className='questions__nav-list'>
            { insightsNavList }
          </ul>
          {currentBlock && <QuestionsBlock block={currentBlock} />}
        </div>
      </div>
    </section>
  );
}

export default Questions;
