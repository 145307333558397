import Question from '../question/question';

import { useState, useEffect } from 'react';

import type { QuestionsBlockType } from '@models/question';
import './questions-block.scss';

interface Props {
  block: QuestionsBlockType;
}

function QuestionsBlock({ block }: Props): JSX.Element {

  const { list } = block;

  const [blockList, setBlockList] = useState(list);

  const questionList = blockList.map((question, index) => (
    <li key={question.title}>
      <Question data={question} onClick={() => handleQuestionClick(index)} />
    </li>
  ));

  const handleQuestionClick = (id: number) => {
    const updatedBlockList = blockList.map((blockItem, index) => ({
      ...blockItem,
      active: (index === id) && !blockItem.active
    }));
    updatedBlockList && setBlockList(updatedBlockList);
  };

  useEffect(() => {
    setBlockList(list);
  }, [list]);

  return (
    <ul className='questions__list'>
      { questionList }
    </ul>
  );
}

export default QuestionsBlock;
