import BlockContact from '@components/blocks/contact/contact';
import BlockSocials from '../socials/socials';
import BaseMap from '@components/base/map/map';
import { contactsList } from '@data';
import './contacts.scss';

function Contacts(): JSX.Element {

  const contacts = contactsList.map((contact): JSX.Element => {
    switch (contact.type) {
      case 'contact': {
        const { title, item } = contact;
        return (
          <li className='contacts__item' key={title}>
            <h3 className='contacts__item-title'>{title}</h3>
            <BlockContact to={item.to} title={item.title} name={item.name} />
          </li>
        );
      }

      case 'socials': {
        const { title } = contact;
        return (
          <li className='contacts__item' key={title}>
            <h3 className='contacts__item-title'>{title}</h3>
            <BlockSocials />
          </li>
        );
      }
      default:
        // Возвращаем пустой элемент или другое значение по умолчанию
        return <li className='contacts__item'>Unknown contact type</li>;
    }
  });

  return (
    <section className='contacts'>
      <div className='contacts__inner'>
        <div className='contacts__content'>
          <h1 className='contacts__title h1'>Contact Us</h1>
          <ul className='contacts__list'>
            {contacts}
          </ul>
        </div>
        <BaseMap />
      </div>
    </section>
  );
}

export default Contacts;
