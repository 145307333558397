import NewsLink from './news-link/news-link';

import type { NewsLinksContent } from '@models/sections';
import './news-links.scss';

interface Props {
  data: NewsLinksContent;
}

function NewsLinks({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, list } = data;

  const newsLinkList = list.map((item) => (
    <li key={item.title}>
      <NewsLink data={item} />
    </li>
  ));

  return (
    <section className='news-links'>
      <div className='news-links__inner'>
        <h2 className='h2 news-links__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
        <ul className='news-links__list'>
          { newsLinkList }
          <li>
            <div className='news-link news-more'>
              <h3 className='news-link__title news-more__title' dangerouslySetInnerHTML={{ __html: 'Аnd more...' }}></h3>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default NewsLinks;
