import './logo.scss';
import { Link } from 'react-router-dom';
import { AppRoute } from '@utils/const';
import BaseSvg from '@components/base/svg/svg';

type LogoProps = {
  className: string;
};


function Logo({ className }: LogoProps): JSX.Element {

  return (
    <Link to={AppRoute.Main} className={`logo ${className}`}>
      <BaseSvg name='logo' />
    </Link>
  );
}

export default Logo;
