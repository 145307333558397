import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import BaseSvg from '@components/base/svg/svg';
import BaseButton from '@components/base/button/button';
import type { NewsContent } from '@models/sections';
import './news.scss';

interface Props {
  data: NewsContent;
}

function News({ data }: Props): JSX.Element {

  if (!data) {
    return <div>Данных нет</div>;
  }

  const { title, cards } = data;

  const newsList = cards.map((item) => (
    <SwiperSlide
      tag='li'
      key={item.title}
      className='news__item'
    >
      <a href={item.link}>
        <div className="news__item-images">
          <picture className='news__item-logo'>
            <img src={item.logo.url} alt={item.logo.title} />
          </picture>
          {
            (item.imgLarge && typeof item.imgLarge === 'object') &&
            <picture className='news__item-image'>
              <img src={item.imgLarge.url} alt={item.imgLarge.alt} />
            </picture>
          }
        </div>
        <h3 className="news__item-title">{item.title}</h3>
        <p className="news__item-text" dangerouslySetInnerHTML={{__html: item.text}}></p>
        <div className="news__item-bottom">
          <span className="news__item-date">{item.date}</span>
          <span className="news__item-source">{item.companyName}</span>
        </div>
      </a>
    </SwiperSlide>
  ));

  return (
    <section className="news">
      <div className="news__inner">
        <div className="news__top">
          <h2 className="h2 news__title">{title}</h2>
          <div className="news__buttons">
            <BaseButton classes="news__button news__button-prev">
              <BaseSvg name="arrow-left" />
            </BaseButton>
            <BaseButton classes="news__button news__button-next">
              <BaseSvg name="arrow-right" />
            </BaseButton>
          </div>
        </div>
        <div className="news__bottom">
          <Swiper
            className='news__slider'
            wrapperTag='ul'
            wrapperClass='news__list'
            modules={[Pagination, Navigation]}
            navigation={
              {
                nextEl: '.news__button-next',
                prevEl: '.news__button-prev',
                disabledClass: 'news__button--disabled'
              }
            }
            pagination={
              {
                el: '.news__pagination-wrapper',
                clickable: true,
                bulletClass: 'news__pagination-bullet',
                bulletActiveClass: 'news__pagination-bullet-active'
              }
            }
            slidesPerView={3}
            speed={500}
            mousewheel
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              641: {
                slidesPerView: 1.5,
              },
              1025: {
                slidesPerView: 2.5
              },
              1281: {
                slidesPerView: 3
              }
            }}
          >
            { newsList }
          </Swiper>
          <div className='news__pagination-wrapper'/>
        </div>
      </div>
    </section>
  );
}

export default News;
