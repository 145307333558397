import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import CareersMain from '@components/blocks/careers-main/careers-main';
import Vacancies from '@components/blocks/vacancies/vacancies';
import BannerLink from '@components/blocks/banner-link/banner-link';
import { careersData } from '@data';
import './careers.scss';

function CareersPage(): JSX.Element {

  const { main, vacancies, bannerLink } = careersData;

  return (
    <>
      <Header />
      <main className='main'>
        <CareersMain data={main} />
        <Vacancies data={vacancies} />
        <BannerLink data={bannerLink} />
      </main>
      <Footer />
    </>
  );
}

export default CareersPage;
