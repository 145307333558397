import classNames from 'classnames';
import { CommodityContent } from '@types';
import './commodity.scss';

interface Props {
  data: CommodityContent;
  reversed?: boolean;
}

function Commodity(props: Props): JSX.Element {

  const { data, reversed = false } = props;
  const { image, title, description, list } = data;

  const classes = classNames(
    'commodity',
    {
      'commodity--reversed': reversed
    }
  );

  const commodityContent = list.map((item) => (
    <li className='commodity__item' key={item.id}>
      <div className='commodity__text' dangerouslySetInnerHTML={{__html: item.text}}></div>
      {item.source && <div className='commodity__source' dangerouslySetInnerHTML={{__html: item.source}}></div>}
    </li>
  ));

  return (
    <li className={classes}>
      <picture className='commodity__image'>
        <img src={`/img/commodities/${image}.png`} alt={`${image}`} />
      </picture>
      <div className='commodity__content'>
        <div className='commodity__top'>
          <h3 className='h3 commodity__title'>{ title }</h3>
          <div className='commodity__description' dangerouslySetInnerHTML={{__html: description}}></div>
        </div>
        <ul className='commodity__list'>
          { commodityContent }
        </ul>
      </div>
    </li>
  );
}

export default Commodity;
