import type { PropsWithChildren } from 'react';
import './privilege.scss';

interface Props {
  title: string;
}

function Privilege(props: PropsWithChildren<Props>): JSX.Element {

  const { children, title } = props;

  return (
    <div className='privilege'>
      <h3 className='privilege__title'>{ title }</h3>
      <div className='privilege__content'>
        {children}
      </div>
    </div>
  );
}

export default Privilege;
