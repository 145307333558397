import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/navigation';
import 'swiper/css';
import BaseButton from '@components/base/button/button';
import type { PromoContent } from '@models/sections';
import './promo.scss';

interface Props {
  data: PromoContent;
}

function Promo({ data }: Props): JSX.Element {

  return (
    <section className='promo'>
      <div className='promo__inner'>
        <h1 className='visually-hidden'>ATME</h1>
        <Swiper
          wrapperTag='ul'
          wrapperClass='promo__list'
          modules={[Pagination]}
          navigation={
            {
              nextEl: '.promo__btn-next',
              prevEl: '.promo__btn-prev',
              disabledClass: 'sl__btn-disabled'
            }
          }
          pagination={
            {
              el: '.promo__pagination-wrapper',
              clickable: true,
              bulletClass: 'promo__pagination-bullet',
              bulletActiveClass: 'promo__pagination-bullet-active'
            }
          }
          slidesPerView={1}
          speed={500}
          mousewheel
        >
          {
            data
              .map(({ imgLarge, imgMedium, imgSmall, title, text, linkName, linkUrl }) => (
                <SwiperSlide
                  tag='li'
                  className='promo__list-item'
                  key={title}
                >
                  <div className='promo__info'>
                    <h2 className='promo__title' dangerouslySetInnerHTML={{ __html: title }}></h2>
                    <div className='promo__text' dangerouslySetInnerHTML={{ __html: text}}></div>
                    {
                      (linkUrl && linkName) && (
                        <BaseButton to={linkUrl} classes='promo__details' outlink>
                          <span>{linkName}</span>
                        </BaseButton>
                      )
                    }
                  </div>
                  {
                    imgLarge &&
                    <picture>
                      {
                        typeof imgMedium === 'object' &&
                        <source srcSet={imgMedium.url}
                          type='image/webp'
                          media='(min-width: 541px) and (max-width: 1024px)'
                        />
                      }
                      {
                        typeof imgSmall === 'object' &&
                        <source srcSet={imgSmall.url}
                          type='image/webp'
                          media='(min-width: 320px) and (max-width: 540px)'
                        />
                      }
                      {
                        typeof imgLarge === 'object' &&
                        <img
                          className='promo__image'
                          src={imgLarge.url}
                          alt={imgLarge.alt}
                        />
                      }
                    </picture>
                  }
                </SwiperSlide>
              ))
          }
        </Swiper>
        { data.length > 1 && (<div className='promo__pagination-wrapper'/>)}
      </div>
    </section>
  );
}

export default Promo;
