import './careers-main.scss';

interface Props {
  data: {
    title: string;
    description: string;
    list: {
      id: number;
      text: string;
    }[];
  };
}

function CareersMain({ data }: Props): JSX.Element {

  const { title, description, list } = data;

  const careersMainList = list.map(({ id, text }) => (
    <li key={id}>
      <span className='careers-main__item' dangerouslySetInnerHTML={{__html: text}}></span>
    </li>
  ));

  return (
    <section className='careers-main'>
      <div className='careers-main__bg'/>
      <div className='careers-main__inner'>
        <div className='careers-main__main'>
          <h1 className='h1 careers-main__title' dangerouslySetInnerHTML={{__html: title}}></h1>
          <p className='careers-main__description' dangerouslySetInnerHTML={{__html: description}}></p>
        </div>
        <div className='careers-main__list-wrap'>
          <h3 className='careers-main__list-title'>Our values:</h3>
          <ul className='careers-main__list'>
            {careersMainList}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default CareersMain;
