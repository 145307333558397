import './why.scss';
import {WhyContent} from '@types';

interface WhyProps {
  data: WhyContent
}


function Why(props: WhyProps): JSX.Element {
  const {
    data
  } = props;

  return (
    <section className='why'>
      <div className='why__inner'>
        <h2 className='h2 why__title'>{ data.title }</h2>
        <ul className={'why__list'}>
          {data.items.map(item => <li className={'why__list-item'}>
            <div className={'why-card'}>
              <h3 className={'why-card__title'} dangerouslySetInnerHTML={{ __html: item.title }}/>
              <div className={'why-card__text'} dangerouslySetInnerHTML={{ __html: item.text }}/>
            </div>
          </li>)}
        </ul>
      </div>
      <picture>
        {/* <source srcSet={'/img/why/why-lg.png'}
          type='image/webp'
          media='(min-width: 1024px) and (max-width: 1280px)'
        /> */}
        <img
          className='why__image'
          src={data.picture}
          alt=''
          aria-hidden={true}
        />
      </picture>
    </section>
  );
}

export default Why;
