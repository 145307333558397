import BaseSvg from '@components/base/svg/svg';

import { useState } from 'react';
import classNames from 'classnames';

import type { QuestionType } from '@models/question';
import './question.scss';

interface Props {
  data: QuestionType;
  onClick: () => void;
}

function Question({ data, onClick }: Props): JSX.Element {

  const { title, text, active } = data;

  /* const [isText, setText] = useState(active); */

  const questionClasses = classNames(
    'question',
    { 'question--active': active }
  );

  /* const handleQuestionClick = () => {
    onClick();
    setTimeout(() => {
      setText(!active);
    });
  }; */

  return (
    <div className={questionClasses}>
      <div className='question__top' onClick={onClick}>
        <h3 className='question__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
        <BaseSvg name='arrow-right' />
      </div>
      <div className='question__text' dangerouslySetInnerHTML={{
        __html: text
      }}
      >
      </div>
    </div>
  );
}

export default Question;
