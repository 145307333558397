import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import Analytics from '@components/blocks/analytics/analytics';
import BannerLink from '@components/blocks/banner-link/banner-link';
import Opportunities from '@components/blocks/opportunities/opportunities';
import PageLinks from '@components/blocks/page-link/page-links';

import { privateEquityData } from '@data';
import type { BreadcrumbsItem } from '@types';
import './private-equity.scss';

function PrivateEquity(): JSX.Element {

  const { analytics, opportunities, bannerLink } = privateEquityData;

  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Private equity' }];

  const pageLinks = {
    prev: {
      link: '/commodities',
      title: 'Commodities'
    },
    next: {
      link: '/private-debt',
      title: 'Private debt'
    }
  };

  return (
    <>
      <Header />
      <main className='main'>
        <Breadcrumbs breadcrumbs={breadcrumbsList}/>
        <Analytics data={analytics} />
        <Opportunities data={opportunities} />
        <PageLinks pagelinks={pageLinks} />
        <BannerLink data={bannerLink} />
      </main>
      <Footer />
    </>
  );
}

export default PrivateEquity;
