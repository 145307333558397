import BaseSvg from '@components/base/svg/svg';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import type { IssuanceBlockType } from '@models/issuance-content';
import './issuance-block.scss';

interface Props {
  block: IssuanceBlockType;
}

function IssuanceBlock({ block }: Props): JSX.Element {

  const { title, navList, contentList } = block;

  const [navListButtons, setNavListButtons] = useState(navList);
  const [currentContent, setCurrentContent] = useState(contentList[0]);

  const menu = navListButtons.map((item) => {
    const navItemClasses = classNames(
      'issuance-block__nav-item',
      {
        'issuance-block__nav-item--active': item.active
      }
    );

    function handleNavClick(id: string) {
      const updatedNavList = navListButtons.map((button) => ({
        ...button,
        active: button.id === id
      }));
      updatedNavList && setNavListButtons(updatedNavList);

      const targetContent = contentList.find((contentItem) => contentItem.id === id);

      targetContent && setCurrentContent(targetContent);
    }

    return (
      <li key={item.title} onClick={() => handleNavClick(item.id)}>
        <div className={navItemClasses}>
          <div className='issuance-block__nav-item-content'>
            {
              item.title &&
              <h4 className='issuance-block__nav-item-title' dangerouslySetInnerHTML={{ __html: item.title }}></h4>
            }
            {
              item.active &&
              <div className='issuance-block__nav-item-text' dangerouslySetInnerHTML={{ __html: item.text }}></div>
            }
          </div>
          <BaseSvg name='arrow-right' />
        </div>
      </li>
    );
  });

  useEffect(() => {
    setNavListButtons(navList);
    setCurrentContent(contentList[0]);
  }, [navList, contentList]);

  return (
    <div className='issuance-block'>
      <div className='issuance-block__main'>
        <h3 className='issuance-block__title' dangerouslySetInnerHTML={{ __html: title }}></h3>
        <ul className='issuance-block__nav-list'>
          { menu }
        </ul>
      </div>
      <div className='issuance-block__content'>
        <picture className='issuance-block__img-wrap'>
          {
            typeof currentContent.imgMedium === 'object' &&
            <source srcSet={currentContent.imgMedium.url}
              type='image/webp'
              media='(min-width: 541px) and (max-width: 1024px)'
            />
          }
          {
            typeof currentContent.imgSmall === 'object' &&
            <source srcSet={currentContent.imgSmall.url}
              type='image/webp'
              media='(min-width: 320px) and (max-width: 540px)'
            />
          }
          {
            typeof currentContent.imgLarge === 'object' &&
            <img
              src={currentContent.imgLarge.url}
              alt={'issuance'}
            />
          }
        </picture>
        <div className='issuance-block__text' dangerouslySetInnerHTML={{ __html: currentContent.text }}></div>
      </div>
    </div>
  );
}

export default IssuanceBlock;
