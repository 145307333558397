import './development.scss';
import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import DevelopmentMain from '@components/blocks/development/development';

function DevelopmentPage(): JSX.Element {

  return (
    <>
      <Header />
      <main className='main'>
        <DevelopmentMain />
      </main>
      <Footer />
    </>
  );
}

export default DevelopmentPage;
