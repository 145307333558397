import { Link } from 'react-router-dom';
import './breadcrumbs.scss';

interface Props {
  breadcrumbs: {
    name: string;
    path?: string;
  }[];
}

function Breadcrumbs(props: Props): JSX.Element {

  const { breadcrumbs } = props;

  const breadcrumbsContent = breadcrumbs.map(({name , path}) => (
    <li key={name} className='breadcrumbs__item'>
      { path ? (
        <Link to={path} className='breadcrumbs__link'>{name}</Link>
      ) : (
        <span className='breadcrumbs__text'>{name}</span>
      )}
    </li>
  ));

  return (
    <div className= 'breadcrumbs'>
      <ul className= 'breadcrumbs__list'>
        { breadcrumbsContent }
      </ul>
    </div>
  );
}

export default Breadcrumbs;
