const baseUrl = process.env.REACT_APP_API_BASE_URL;

interface ApiResponse {
  body: ReadableStream;
  bodyUsed: boolean;
  headers: Headers;
  ok: boolean;
  redirected: boolean;
  status: number;
  statusText: string;
  type: string;
  url: string;
}

function _handleResponse<T>(response: Response): T | Promise<T> {
  if (response.ok) {
    return response.json();
  } else {
    console.log('_handleResponse rejection');
    return Promise.reject(response.statusText);
  }
}

export default function apiFetch<T>(path: string, options?: RequestInit | undefined): Promise<T> {
  if (!baseUrl) {
    throw new Error('Base Url is not defined');
  }

  return fetch(`${baseUrl}${path}`, {
    ...options,
  })
    .then(_handleResponse<T>);
}
