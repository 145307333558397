import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Feedback from '@components/blocks/feedback/feedback';
import './feedback.scss';

function FeedbackPage(): JSX.Element {

  return (
    <>
      <Header />
      <main className='main'>
        <Feedback />
      </main>
      <Footer />
    </>
  );
}

export default FeedbackPage;
