import BaseButton from '@components/base/button/button';
import { createMarkup } from '@utils/utils';
import type { BannerLinkContent } from '@types';
import classNames from 'classnames';
import './banner-link.scss';

interface Props {
  data: BannerLinkContent;
}

function BannerLink(props: Props): JSX.Element {

  const { title, text, buttonName, href, image, secondary } = props.data;

  const bannerLinkClasses = classNames(
    'banner-link',
    {
      'banner-link--secondary': secondary
    }
  );

  return (
    <section className={bannerLinkClasses}>
      <div className="banner-link__inner">
        <div className="banner-link__top">
          <h2 className='h2 banner-link__title' dangerouslySetInnerHTML={createMarkup(title)}></h2>
          {text && (
            <p className="banner-link__text" dangerouslySetInnerHTML={createMarkup(text)}></p>
          )}
        </div>
        <BaseButton to={href} classes='banner-link__button' outlink primary>
          <span>{ buttonName }</span>
        </BaseButton>
      </div>
      {image && (
        <img
          className='promo__image'
          src={`/img/banner-link/${image}.png`}
          alt={`${image}`}
        />
      )}
    </section>
  );
}

export default BannerLink;
