import BaseSvg from '@components/base/svg/svg';
import type { InfoMiningCompanies } from '@types';
import './mining-info.scss';

interface Props {
  data: InfoMiningCompanies;
}

function MiningInfo({ data }: Props): JSX.Element {

  const { list } = data;

  const infoList = list.map(({id, title, icon, description}) => (
    <li className='mining-info__item' key={id}>
      <div className='mining-info__item-main'>
        <h3 className='mining-info__item-title' dangerouslySetInnerHTML={{__html: title}}></h3>
        <BaseSvg name={icon} />
      </div>
      <div className='mining-info__item-description' dangerouslySetInnerHTML={{__html: description}}></div>
    </li>
  ));

  return (
    <section className='mining-info'>
      <div className='mining-info__inner'>
        <ul className='mining-info__list'>
          { infoList }
        </ul>
      </div>
    </section>
  );
}

export default MiningInfo;
