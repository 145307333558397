import './contacts.scss';
import Header from '@components/layout/header/header';
import Footer from '@components/layout/footer/footer';
import Contacts from '@components/blocks/contacts/contacts';
import Breadcrumbs from '@components/base/breadcrumbs/breadcrumbs';
import type { BreadcrumbsItem } from '@types';

function ContactsPage(): JSX.Element {
  const breadcrumbsList: BreadcrumbsItem[] = [{ name: 'ATME', path: '/' }, { name: 'Contact Us' }];

  return (
    <>
      <Header />
      <main className='main'>
        <Breadcrumbs breadcrumbs={breadcrumbsList}/>
        <Contacts />
      </main>
      <Footer />
    </>
  );
}

export default ContactsPage;
