import { useState, type PropsWithChildren, useEffect } from 'react';
import './modal.scss';

type ModalProps = {
  isOpened: boolean;
  onClose: () => void;
};

function Modal(props: PropsWithChildren<ModalProps>): JSX.Element {
  const {
    children,
    isOpened,
    onClose
  } = props;

  const [modalState, setModalState] = useState<boolean>(isOpened);

  useEffect(() => {
    setModalState(isOpened);
  }, [isOpened]);

  const onCloseClick = (): void => {
    setModalState(false);
    onClose();
  };

  return (
    <div className={`modal ${modalState ? '_opened' : ''}`}>
      <div className='modal__inner'>
        <button
          className='modal__close'
          type='button'
          onClick={onCloseClick}
        >
          <span/>
        </button>
        {children}
      </div>
      <div className='modal__overlay' onClick={onCloseClick}></div>
    </div>
  )
}

export default Modal;
