const AppRoute: { [index: string]: string } = {
  Main: '/',
  DevelopmentPage: 'development',
  ContactsPage: 'contacts',
  AboutPage: 'about',
  RaiseCapitalPage: 'raise-capital',
  MiningCompaniesPage: 'mining-companies',
  BusinessesPage: 'businesses',
  RealEstatePage: 'real-estate',
  ProducersPage: 'producers',
  PrivateEquity: 'private-equity',
  PrivateDebt: 'private-debt',
  CommoditiesPage: 'commodities',
  CareersPage: 'careers',
  ServicesPage: 'services',
  FeedbackPage: 'feedback',
  HowItWorksPage: 'how-it-works',
  ResourcesPage: 'resources',
  NotFound: '*'
};

export { AppRoute };
