import './banner.scss';
import { BannerItem } from '@types';

interface BannerProps {
  data: BannerItem;
}


function Banner(props: BannerProps): JSX.Element {
  const {
    data
  } = props;

  return (
    <section className='banner'>
      <div className='banner__inner'>
        <h1 className='banner__title'>{ data.title }</h1>
        <div className={'banner__text'} dangerouslySetInnerHTML={{ __html: data.text }}/>
      </div>
      <picture>
        <source srcSet={data.picture.sizes['1536x1536']}
          type='image/webp'
          media='(min-width: 1025px) and (max-width: 1280px)'
        />
        <source srcSet={data.picture.sizes.large}
          type='image/webp'
          media='(min-width: 541px) and (max-width: 1024px)'
        />
        <source srcSet={data.picture.sizes.medium}
          type='image/webp'
          media='(min-width: 320px) and (max-width: 540px)'
        />
        <img
          className='banner__image'
          src={data.picture.url}
          alt=''
          aria-hidden
        />
      </picture>
    </section>
  );
}

export default Banner;
